import {request} from "../request";
//初始化tab
export function initTab(tabData){
    return request({
        url:'safety/indexes',
        method:'get',
        params:tabData
    })
}
//添加操作
export function addItem(addData){
    return request({
        url:'safety/add',
        method:'post',
        params:addData
    })
}
//编辑操作
export function updateItem(updateData){
    return request({
        url:'safety/update',
        method:'post',
        params:updateData
    })
}
//删除操作
export function deleteItem(deleteData){
    return request({
        url:'safety/delete',
        method:'post',
        params:deleteData
    })
}
