<template>

  <div class="container_safe">

    <v-card>

      <v-snackbar

          :value=snackbarDeleteFail
          absolute
          outlined
          color="error"
          top
      >
        有下层指标，删除失败
      </v-snackbar>
      <v-snackbar

          :value=snackbarDelete
          absolute
          outlined
          color="error"
          top
      >
        删除成功
      </v-snackbar>
      <v-snackbar

          :value=snackbarAdd
          absolute
          outlined
          color="success"
          top
      >
        添加成功
      </v-snackbar>
      <v-snackbar

          :value=snackbarUpdate
          absolute
          outlined
          color="success"
          top
      >
        修改成功
      </v-snackbar>
      <v-tabs
          color="deep-purple accent-4"
          left
      >
        <v-tab @click="clickTab(1)">承重系统</v-tab>
        <v-tab @click="clickTab(2)">非承重围护及建筑附属物</v-tab>
        <v-tab @click="clickTab(3)">设施设备</v-tab>

        <v-tab-item
            v-for="n in 3"
            :key="n"
        >
          <v-container fluid >
            <!--表格-->
            <template>
              <v-data-table
                  :loading="loading"
                  loading-text="加载中,请稍等..."
                  :headers="headers"
                  :items="desserts"
                  sort-by="parentName"
                  class="elevation-1"
                  :search="search"
                  :footer-props="{
       showFirstLastPage: true,
       itemsPerPageText:'每页行数：',
    }"
              >
                <template v-slot:top>
                  <v-toolbar
                      flat
                  >
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="指标层级"
                        single-line
                        hide-details
                        style="margin: 0 20px"

                    ></v-text-field>

                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                          添加
                        </v-btn>
                      </template>
                      <template >
                        <v-card >
                          <v-snackbar

                              :value=snackbar
                              absolute
                              color="error"
                              top
                          >
                            请按照输入内容
                          </v-snackbar>
                          <v-snackbar

                              :value=snackbarUpdateFail
                              absolute
                              outlined
                              color="error"
                              top
                          >
                            {{updateText}}
                          </v-snackbar>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                  <v-text-field
                                      v-model="editedItem.name"
                                      label="安全指标名称"
                                      :rules="rules"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                  <v-autocomplete

                                      v-model="editedItem.parentName"
                                      label="上一层级名称"
                                      :items="upItems"
                                      auto-select-first
                                      clearable
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                            >
                              取消
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                              确定
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>

                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">你确定要删除这一项?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">确定</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <!--                                删除 修改操作-->
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn
                      color="primary"
                      @click="initialize"
                  >
                    Reset
                  </v-btn>
                </template>
              </v-data-table>
            </template>

          </v-container>
        </v-tab-item>

      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {initTab} from "@/network/Details/safetyManagement";
import {addItem} from "@/network/Details/safetyManagement";
import {updateItem} from "@/network/Details/safetyManagement";
import {deleteItem} from "@/network/Details/safetyManagement";
export default {
  name: "SafetyManagement",
  data () {
    return {
      loading:true,
      edItem:'',
      rules: [
        value => !!value || 'Required.',
        // value => (value && value.length >= 3) || 'Min 3 characters',
      ],
      search: '',
      updateText:'',
      snackbarDelete:false,
      snackbarDeleteFail:false,
      snackbarUpdateFail:false,
      snackbarAdd:false,
      snackbarUpdate:false,
      snackbar:false,
      dialog: false,
      dialogDelete: false,
      upItems: [],
      headers: [
        {
          text: '安全指标',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '40%'
        },
        { text: '上一层级', value: 'parentName', },
        { text: '操作', value: 'actions', sortable: false ,width: '20%'},
      ],
      originDesserts:[],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        parentName: '',
      },
      defaultItem: {
        name: '',
        parentName: '',

      },
      selectItems: ['1', '2', '3', '4'],

    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? '添加安全指标' : '编辑'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      initTab({
        name:'承重系统'
      }).then(res=>{
        this.originDesserts=res.data
        this.upItems=[]
        for(let i=0;i<res.data.length;i++){
          this.upItems.push(res.data[i].name)
        }
        this.desserts=this.originDesserts.slice(1, res.data.length);
        this.loading = false;
      }).catch(err=>{
        console.log(err)
      })
    },

    editItem (item) {
      this.edItem=item
      console.log(this.edItem)
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    upItemClick(item){
      console.log(123);
      console.log(item);
    },

    deleteItemConfirm (item) {
      console.log(this.editedIndex);
      let deleteIndex
      deleteIndex=this.desserts[this.editedIndex].safetyId
      console.log(deleteIndex);
      deleteItem({
        safetyId:deleteIndex
      }).then(res=>{
        if(res.message=='删除成功'){
          console.log(res);
          this.snackbarDelete=true
          setTimeout(()=>{this.snackbarDelete=false},2000)
          this.desserts.splice(this.editedIndex, 1)
          this.originDesserts.splice(this.editedIndex+1, 1)
          this.upItems.splice(this.editedIndex+1, 1)
          this.closeDelete()
        }
        else{
          this.snackbarDeleteFail=true
          setTimeout(()=>{this.snackbarDeleteFail=false},2000)
          this.closeDelete()
        }

      }).catch(err=>{
        console.log(err)
      })

    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if(this.editedItem.name===''){
        this.snackbar=true
        setTimeout(()=>{this.snackbar=false},2000)
        return
      }
      if (this.editedIndex > -1) {
        // if(this.editedItem.name===this.editedItem.parentName){
        //     this.snackbarUpdateFail=true
        //     setTimeout(()=>{this.snackbarUpdateFail=false},2000)
        //     return;
        // }
        let safetyParentId
        console.log(this.editedItem.parentName);
        console.log(this.originDesserts)
        for(let i=0;i<this.originDesserts.length;i++){
          if(this.originDesserts[i].name===this.editedItem.parentName){
            safetyParentId=this.originDesserts[i].safetyId                        }
        }
        console.log(safetyParentId);
        updateItem({
          name:this.editedItem.name,
          parentId:safetyParentId,
          safetyId:this.edItem.safetyId,
        }).then(res=>{
          if(res.message=="更新成功"){
            console.log(res);
            this.snackbarUpdate=true
            setTimeout(()=>{this.snackbarUpdate=false},2000)
            this.close()
          }
          else{
            this.updateText=res.message
            this.snackbarUpdateFail=true
            setTimeout(()=>{this.snackbarUpdateFail=false},2000)
            return;
            console.log(res)
          }

        }).catch(err=>{
          console.log(err)
        })
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
        Object.assign(this.originDesserts[this.editedIndex+1], this.editedItem)
        Object.assign(this.upItems[this.editedIndex+1], this.editedItem.name)
      } else {
        let addparentId
        for(let i=0;i<this.originDesserts.length;i++){
          if(this.editedItem.parentName===this.originDesserts[i].name){
            addparentId=this.originDesserts[i].safetyId
            console.log(addparentId);
          }
        }

        addItem({
          name:this.editedItem.name,
          parentId:addparentId
        }).then(res=>{
          if(res.message=="添加成功"){
            console.log(res);
            this.snackbarAdd=true
            setTimeout(()=>{this.snackbarAdd=false},2000)
            this.originDesserts.push(this.editedItem)
            this.desserts.push(this.editedItem)
            console.log(this.editedItem);
            this.upItems.push(this.editedItem.name)
            this.close()
          }
          else {
            console.log(123);
          }

        }).catch(err=>{
          console.log(err)
        })

      }
    },


    clickTab(item){
      if(item===1){
        console.log(1);
        initTab({
          name:'承重系统'
        }).then(res=>{

          console.log(res);
          this.originDesserts=res.data
          this.upItems=[]
          for(let i=0;i<res.data.length;i++){
            this.upItems.push(res.data[i].name)
          }
          this.desserts=this.originDesserts.slice(1, res.data.length)

        }).catch(err=>{
          console.log(err)
        })
      }
      else if(item===2){
        console.log(2);
        initTab({
          name:'非承重围护及建筑附属物'
        }).then(res=>{
          this.originDesserts=res.data
          this.upItems=[]
          for(let i=0;i<res.data.length;i++){
            this.upItems.push(res.data[i].name)
          }
          this.desserts=this.originDesserts.slice(1, res.data.length)
        }).catch(err=>{
          console.log(err)
        })
      }
      else if(item===3){
        initTab({
          name:'设施设备'
        }).then(res=>{
          this.originDesserts=res.data
          console.log(this.originDesserts);
          this.upItems=[]
          for(let i=0;i<res.data.length;i++){
            this.upItems.push(res.data[i].name)
          }
          this.desserts=this.originDesserts.slice(1, res.data.length)
        }).catch(err=>{
          console.log(err)
        })
      }
    },
  },

}
</script>

<style scoped>

</style>
